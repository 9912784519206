@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@600;900&family=Sora:wght@400;500&family=Ubuntu:wght@700&display=swap&family=Courgette&display=swap");
@import "./breakpoints.scss";
@import "./variables.scss";
@import "./pages/header.scss";
@import "./pages/home.scss";
@import "./overlay.scss";
@import "./pages/footer.scss";
@import "./pages/gallery.scss";
@import "./pages/about.scss";
@import "./pages/submit.scss";
@import "./pages/chapters.scss";
@import "./pages/piece.scss";
@import "./pages/modal.scss";

*::selection {
  background-color: $gray-bright;
}

body {
  visibility: hidden;
  background:$white;
  margin: 0;
  font-family: "Sora", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-decoration: none;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

a,
button,
div[role="link"],
.btn {
  text-decoration: none;
  outline: none !important;
  -webkit-tap-highlight-color: transparent !important;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  @include media("<=md") {
    cursor: default;
  }
  @media screen and (max-height: 540px) and (orientation: landscape) {
    cursor: default;
  }
  &:hover {
    cursor: pointer;
  }
}

* {
  -webkit-tap-highlight-color: transparent;
}

// scrollbar
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: $gray-bright;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #4b6f90;
  transition: all 0.55s ease;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #c2d8b9;
}

.page {
  min-height: calc(calc(var(--vh, 1vh) * 100) - 62px);
  margin-top: 62px;
  .lead-p {
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    font-size: 1.25em;
    color: $black;
    b {
      color: $theme-darker;
      font-size: 1.03em;
    }
  }
  p {
    font-size: 1.1em;
    color: #555;
    b {
      color: #444;
    }
  }
}

.action-btn {
  font-size: 1.25em;
  padding: 0.3em 1em;
  border-radius: 2px;
  font-weight: 500;
  transition: 0.21s ease;
  position: relative;
  text-decoration: none;
  display: inline-block;
  &:hover {
    transform: translateY(-3px);
    text-decoration: none;
  }
  &.main-action {
    &:hover {
      transform: translateY(-3px);
    }
  }
}

// underline link
a.underline {
  position: relative;
  text-decoration: none;
  color: white;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0.1em;
    min-height: 2px;
    background-color: currentColor;
    transform-origin: right;
    border: 0;
    transition: transform 0.25s ease;
  }

  &:hover {
    &:after {
      transform: scaleX(0);
    }
  }
}

// section hero

.section-hero {
  user-select: none;
  background-size: cover;
  position: relative;
  color: $light-bright;
  position: relative;
  .container {
    padding: 6.9em 2em;
    @include media("<=md") {
      padding: 5em 1em;
    }
    h1 {
      margin: auto;
      margin-bottom: 0.18em;
      max-width: 80vw;
      font-family: "Raleway", -apple-system, BlinkMacSystemFont, "Segoe UI",
        "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
      color: white;
      font-weight: 700;
      border-top: 4px white solid;
      border-bottom: 4px white solid;
      font-size: 6em;
      @include media("<=md") {
        font-size: 5em;
      }

      @include media("<=sm") {
        font-size: 4.2em;
      }
    }
  }
  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 2em;
    background: $black;
    opacity: 0.3;
    z-index: 1;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    height: 100%;
    width: 100%;
    z-index: -1;
    user-select: none;
  }
}

.section-header {
  text-align: center;
  padding-top: 2.5em;
  position: relative;
  margin-bottom: 1.5em;
  h1 {
    font-size: 3em;
    margin-bottom: 0;
    font-family: "Ubuntu", -apple-system, BlinkMacSystemFont, "Segoe UI",
      "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
  }
  &::after {
    content: "";
    display: block;
    text-align: center;
    width: 3.5em;
    height: 0.55em;
    background-color: #4b6f90;
    margin: 0.42em auto 0.69em auto;
  }
}

.section-header-inline {
  text-align: center;
  position: relative;
  margin-bottom: 1.5em;
  h1 {
    font-size: 3em;
    margin-bottom: 0;
    font-family: "Ubuntu", -apple-system, BlinkMacSystemFont, "Segoe UI",
      "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
  }
  &::after {
    content: "";
    display: block;
    text-align: center;
    width: 3.5em;
    height: 0.55em;
    background-color: #4b6f90;
    margin: 0.42em auto 0.69em auto;
  }
}

.gray-bg {
  background: $gray-brightest;
}

.theme {
  color: #4b6f90;
}
