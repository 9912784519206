// variables
$black-bright: #152828;
$black: hsla(180, 30%, 5%, 1);
$black-dark: #070d0d;
$gray-brightest: #e5ebea;
$gray-bright: #dcd4d0;
$gray: rgb(128, 143, 141);
$gray-dark: #727e7c;
$light-bright: #fbfcee;
$light: rgb(249, 252, 215);
$light-dark: #b4976a;
$theme-brightest: #c2d8b9;
$theme-brighter: #c2d8b9;
$theme-bright: #95C5FD;
$theme-bright-translucent: #7ca8d1cb;
$theme: #cc313e;
$theme-darker: #5f292d;
$theme-darkest: #331618;
$white: #FFFFFF;
$green: #9fc379;
$theme-peach: #e88e67;
$theme-green: #9fc379;
$theme-blue: #8191d8;