.navbar {
  background-color: $black;
  font-size: 1.2em;
  font-weight: 400;
  -webkit-box-shadow: 0 0.69rem 1rem rgba(0, 0, 0, 0.16) !important;
  box-shadow: 0 0.69rem 1rem rgba(0, 0, 0, 0.16) !important;

  .navbar-brand {
    color: $light-bright;
    #navlogotext {
      position: relative;
      margin: 0;
      // top: 0.15em;
      text-transform: none;
      font-size: 1.2em;
      font-weight: bold;
      -moz-user-select: none;
      -webkit-user-select: none;
      user-select: none;
      img {
        height: 2.0em;
      }
    }
  }

  .navbar-toggler {
    border: none;
    background: transparent !important;

    &.collapsed,
    &:focus,
    &:active {
      border: none;
      outline: none;
    }

    /* Hamburger menu */
    .icon-bar {
      width: 25px;
      height: 3px;
      background-color: $light-bright;
      display: block;
      transition: all 0.2s;
      margin-top: 4px;
      border-radius: 2px;

      &.top-bar {
        transform: rotate(45deg);
        transform-origin: 10% 10%;
      }
      &.middle-bar {
        opacity: 0;
      }
      &.bottom-bar {
        transform: rotate(-45deg);
        transform-origin: 10% 90%;
      }
    }

    &.collapsed {
      .top-bar {
        transform: rotate(0);
      }
      .middle-bar {
        opacity: 1;
      }
      .bottom-bar {
        transform: rotate(0);
      }
    }
  }

  .nav-link {
    transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    color: $gray;
  }

  .show > .dropdown-menu {
    max-height: 100vh;
    visibility: visible;
    opacity: 1;
    pointer-events: all;

    @include media("<=md") {
      padding: 8px 0;
    }
  }

  .dropdown-menu {
    pointer-events: none;
    display: block;
    margin: 0;
    padding: 0;
    max-height: 0;
    visibility: hidden;
    transition: max-height 0.99s ease, opacity 0.45s ease, visibility 0.69s ease,
      padding 0.75s ease;
    overflow: hidden;
    opacity: 0;
    padding: 8px 0;
    @include media("<=md") {
      padding: 0;
    }
  }

  .dropdown-menu {
    -webkit-box-shadow: 0 0.69rem 1rem rgba(0, 0, 0, 0.25) !important;
    box-shadow: 0 0.69rem 1rem rgba(0, 0, 0, 0.25) !important;
    background: $black-bright;
    .dropdown-divider {
      border: 0.069px $black solid;
    }
    .dropdown-item {
      color: $white;
      transition: 0.69s ease;
      &.active {
        color: $light-bright;
        background-color: $black-bright;
        transition: inherit;
      }
      &:hover {
        background-color: $black;
      }
    }
  }

  .active .nav-link {
    color: $light-bright;
  }
  .donate-btn {
    margin-left: 15px;
    padding: 8px 30px 8px 30px;
    font-weight: 600;
    &.active {
      background-color: $gray;
      transition: inherit;
    }
    &:hover {
      background-color: $green;
    }
  }
}

/* NavItem underline animation */
@media (min-width: 992px) {
  .nav-link {
    position: relative;
    margin-left: 13px;
  }

  .nav-link::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2.5px;
    top: 88%;
    bottom: 0;
    left: 0;
    background-color: $theme;
    visibility: hidden;
    transform: scaleX(0);
    transition: all 0.3s ease-in-out 0s;
    border-radius: 1.5px;
  }

  .active .nav-link::before {
    visibility: visible;
    transform: scaleX(1);
  }

  .inactive .nav-link::before {
    visibility: hidden;
    transform: scaleX(0);
  }

  .nav-link:hover::before {
    visibility: visible;
    transform: scaleX(1);
  }
}
