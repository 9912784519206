.overlays {
  position: absolute;
  height: calc(var(--vh, 1vh) * 100);
  .overlay {
    position: fixed;
    z-index: 1030;
    height: 25%;
    width: 100vw;
    top: 0;
    background-color: $black;
    transition: height 0.55s ease;
    &:nth-child(2) {
      top: 25%;
    }
    &:nth-child(3) {
      top: 50%;
    }
    &:nth-child(4) {
      top: 75%;
    }
  }
}
