// ALL GALLERY CSS

// GALLERY OVERVIEW CSS
.gallery {
  @include media("<sm") {
    .smallContainer {
      display: block;
    }
    .largeContainer {
      display: none;
    }
  }
  @include media(">=sm") {
    .smallContainer {
      display: none;
    }
    .largeContainer {
      display: block;
    }
  }

  @media screen and (max-height: 540px) and (orientation: landscape) {
    .smallContainer {
      display: block;
    }
    .largeContainer {
      display: none;
    }
  }
  h1 {
    font-weight: 800;
    margin-bottom: 0;
    color: white;
    @include media("<=lg") {
      font-size: 2.2em;
    }
  }
  h2 {
    font-size: 1.8em;
    padding: 0.25em 0 0 0;
    color: $gray-bright;
    @include media("<=lg") {
      font-size: 1.6em;
    }
    @include media("<=md") {
      display: none;
    }
    @media screen and (max-height: 540px) and (orientation: landscape) {
      display: none;
    }
  }

  .content-container {
    display: flex;
    align-items: center;
    min-height: calc(calc(var(--vh, 1vh) * 100) - 62px);
    justify-content: center;
    .blm-column {
      flex: 1 40%;
      .section {
        &.blm {
          display: block;
          width: 100%;
        }
        position: relative;
        min-height: calc(calc(var(--vh, 1vh) * 100) - 62px);
        padding-top: 2em;
      }
    }
    .rest-column {
      display: flex;
      flex-flow: column nowrap;
      min-height: calc(calc(var(--vh, 1vh) * 100) - 62px);
      flex: 1 60%;
    }
    .submit {
      user-select: none;
      text-align: center;
      padding: 1em;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        z-index: -1;
        filter: brightness(0.69);
        background: $black-bright;
        background-size: cover;
      }
      h1 {
        padding: 0;
      }
      .action-btn {
        background-color: $theme-bright;
        color: $light-bright;
        filter: drop-shadow(6px 6px 0 rgba(0, 0, 0, 0.69));
        &:hover {
          background-color: $theme-brighter;
          filter: drop-shadow(7px 9px 0 rgba(0, 0, 0, 0.55));
        }
      }
    }

    .art-sections {
      display: flex;
      flex-flow: column nowrap;
      flex: 4;
      .row1 {
        display: flex;
        flex: 1;
        .visualarts {
          flex: 0 50%;
          display: flex;
        }
        .creativewriting {
          flex: 0 50%;
        }
      }
      .row2 {
        display: flex;
        flex: 1;
        .photography {
          flex: 0 50%;
        }
        .music {
          flex: 0 50%;
        }
      }
    }
  }

  .submit {
    @include media("<=md") {
      user-select: none;
      text-align: center;
      padding: 2em 1em;
      background: $black-bright;
      h1 {
        padding: 0;
      }
      h4 {
        color: $gray-bright;
      }
      .action-btn {
        background-color: $theme-bright;
        color: $light-bright;
        filter: drop-shadow(6px 6px 0 rgba(0, 0, 0, 0.69));
        &:hover {
          background-color: $theme-brighter;
          filter: drop-shadow(7px 9px 0 rgba(0, 0, 0, 0.55));
        }
      }
    }
    @media screen and (max-height: 540px) and (orientation: landscape) {
      user-select: none;
      text-align: center;
      padding: 2em 1em;
      background-color: $theme-darker;
      h1 {
        padding: 0;
      }
      h4 {
        color: $gray-bright;
      }
      .action-btn {
        background-color: $theme-bright;
        color: $light-bright;
        filter: drop-shadow(6px 6px 0 rgba(0, 0, 0, 0.69));
        &:hover {
          background-color: $theme-brighter;
          filter: drop-shadow(7px 9px 0 rgba(0, 0, 0, 0.55));
        }
      }
    }
  }

  .section {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 0 2em;
    overflow: hidden;
    user-select: none;
    width: 100%;
    min-width: 100px;
    @include media("<=md") {
      min-height: calc(calc(100vh - 62px) / 3);
      h1 {
        padding: 0;
      }
    }

    @media screen and (max-height: 540px) and (orientation: landscape) {
      min-height: calc(calc(100vh - 62px) / 2);
      h1 {
        padding: 0;
      }
    }
    .section-bg {
      background: $black-bright;
      position: absolute;
      z-index: -1;
      width: 100%;
      height: 100%;
      top: 0;
      right: 0;
      object-fit: cover;
      transition: all 0.55s ease-in-out;
      filter: brightness(75%);
      transform: scale3d(1.03, 1.03, 1.03);
    }
    &:hover {
      cursor: pointer;
      .section-bg {
        transform: scale3d(1.045, 1.045, 1.045) rotate(0.2deg);
        filter: brightness(100%);
      }
    }
  }
}

// GRID CSS
.grid-layout {
  .img-col {
    text-align: center;
    padding: 6.9px;
    max-height: 90vh;
    @include media(">=md") {
      max-height: 59vh;
    }
    &:hover {
      .gallery-image {
        transform: scale(1.1);
        filter: brightness(1.05);
      }
      .title {
        opacity: 1;
        bottom: 0;
      }
    }
    .title {
      position: absolute;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      bottom: -25%;
      min-height: 4.2em;
      height: 25%;
      transition: 0.42s ease-out;
      opacity: 0;
      padding: 3em 1em;
      background-color: rgba(0, 0, 0, 0.66);
      color: $gray-bright;
      b {
        color: white;
      }
      &.has-award {
        opacity: 1;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.76);
        filter: brightness(1.25);
        border-top: 6px solid rgba(0, 0, 0, .76);
        border-bottom: 6px solid rgba(0, 0, 0, .76);
      }
      @include media("<=md") {
        opacity: 1;
        bottom: 0;
      }
    }
  }
  a {
    position: relative;
    text-decoration: none;
    color: $black;
    overflow: hidden;
    border-radius: 0.2em;
    .gallery-image {
      object-fit: cover;
      transition: 0.42s ease-in-out;
      transform: scale(1.025);
      filter: brightness(0.95);
    }
  }
}

.filter-grid {
  input {
    font-size: 1.2em;
    padding: 0.75em 1.2em;
    width: 100%;
    border: none;
    border-radius: 2.5em;
    box-shadow: 0 0.1em 0.25em rgba(0, 0, 0, 0.13);
    &:focus {
      outline: none;
    }
  }
}
