.about {
  .who-we-are {
    padding: 0.42em 1em 2.69em 1em;
  }
  /*--------------------------------------------------------------
  # Team
  --------------------------------------------------------------*/
  .team {
    .row {
      padding-top: 0.5em;
    }
    // padding-bottom: 2.5em;
    .member {
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 20px;
      overflow: hidden;
      border-radius: 4px;
      background: #fff;
      box-shadow: 0px 2px 15px rgba(0, 20, 51, 0.21);
      transition: 0.55s ease-in-out;
      &:hover {
        transform: translateY(-4.2px);
        box-shadow: 3px 2px 21px rgba(0, 20, 51, 0.31);
        .member-img {
          filter: brightness(1.05);
        }
      }
      .member-img {
        filter: brightness(0.95);
        transition: 0.55s ease-in-out;
        background: $black-bright;
        position: relative;
        overflow: hidden;
      }
      .member-info {
        padding: 25px 15px;
        h4 {
          font-weight: 700;
          margin-bottom: 5px;
          font-size: 18px;
          color: $black;
        }
        p {
          font-style: italic;
          font-size: 14px;
          line-height: 26px;
          color: $gray;
        }
        span {
          display: block;
          font-size: 13px;
          font-weight: 400;
          color: $gray;
        }
      }
      .social {
        position: absolute;
        left: 0;
        bottom: 30px;
        right: 0;
        opacity: 0;
        transition: ease-in-out 0.55s;
        text-align: center;
        a {
          transition: color 0.55s;
          color: $black;
          margin: 0 3px;
          padding-top: 7px;
          border-radius: 4px;
          width: 36px;
          height: 36px;
          background: $theme-bright-translucent;
          display: inline-block;
          transition: ease-in-out 0.55s;
          color: $light-bright;
          &:hover {
            background: $theme-bright;
          }
          i {
            font-size: 18px;
          }
        }
      }
      .local-profile {
        width: 100%;
        height: 0;
        padding-bottom: 100%;
        img {
          width: 100%;
          object-fit: cover;
        }
      }

      &:hover .social {
        opacity: 1;
        bottom: 15px;
      }
      @include media("<=575px") {
        .social {
          opacity: 1;
          bottom: 15px;
          a {
            padding: 12px;
            margin: 0 3px;
            border-radius: 4px;
            width: 55px;
            height: 55px;
            background-color: $theme-bright;
            i {
              font-size: 30px;
            }
          }
        }
      }
    }
  }
}
