.footer {
  background-color: $black;
  color: $light-bright;
  padding: 2.5em 0 1em 0;
  transition: 0.55s ease;

  .join-newsletter {
    .action-btn {
      &:hover {
        cursor: pointer;
      }
      margin: 0.42em 2px 1em 2px;
      background-color: black;
      border: 0.15em $light-bright solid;
      color: $light-bright;
      filter: drop-shadow(6px 6px 0 rgba(255, 255, 255, 0.69));
      &:hover {
        filter: drop-shadow(7px 9px 0 rgba(255, 255, 255, 0.6));
      }
      &.main-action {
        background-color: $light-bright;
        color: black;
      }
    }
  }

  .social {
    font-size: 2em;
    a {
      padding: 0;
    }
    * {
      padding: 0 0.2em;
    }
    .fa {
      transition: 0.42s ease-in-out;
      &:hover {
        cursor: pointer;
        color: $gray;
      }
    }
  }

  a {
    color: $light-bright;
  }

  .email {
    font-size: 1.25em;
    color: white;
    line-height: 1.1;
  }

  .copyright {
    font-family: "Courgette", -apple-system, BlinkMacSystemFont, "Segoe UI",
      "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
    color: $gray;
  }
}
