.piece {
  .main-container {
    margin: 5em auto 2.5em auto;
  }
  .button {
    background-color: $gray-bright;
    margin: 0.42em 0;
    transition: 0.42s ease-in-out;
    font-size: 14px;
    padding: 6px 12px;
    margin-bottom: 0;
    display: inline-block;
    text-decoration: none;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    border-radius: 0.3em;
    color: $black-bright;
    margin-bottom: 0.5em;
    &:hover {
      background-color: #ccc;
    }
  }
  .card {
    border: 0;
    box-shadow: 0 0.1em 0.25em rgba(0, 0, 0, 0.25);
    h1 {
      font-size: 1.69em;
      color: $gray-dark;
    }
    h2 {
      font-size: 1.9em;
      .artist {
        color: $theme;
        font-weight: bold;
      }
    }
    h3 {
      font-size: 1.2em;
    }
    p {
      font-size: 0.9em;
    }
    a {
      text-decoration: none;
    }
    .otherPiece {
      display: block;
      margin: 0 3px;
      color: $gray-dark;
      padding: 0.2em 1em;
      border: $gray-dark 1px solid;
      border-radius: 2em;
      transition: 0.42s ease-in-out;
      &:hover {
        background-color: $gray-bright;
        color: #555;
      }
    }
  }
  .image {
    // don't include writing images
    height: 90vh;
    object-fit: cover;
    width: 100%;
    border-radius: 0.25em;
    box-shadow: 0 0.1em 0.25em rgba(0, 0, 0, 0.25);
  }
  .writing-img {
    max-width: 80vw;
  }
  .writing {
    background-color: white;
    border-radius: 0;
    border: 2px $theme solid;
  }
}
