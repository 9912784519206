/*** FONTS ***/
// @import url(https://fonts.googleapis.com/css?family=Montserrat:900|Raleway:400,400i,700,700i);



// /*** VARIABLES ***/
// /* Colors */
// $black: #1d1f20;
// $blue: #67b6ff;
// $green: #67b6ff;
// $yellow: #67b6ff;
// $light: #e5ebea;



// /*** EXTEND ***/
// /* box-shadow */
// %boxshadow {
//   box-shadow: 0.25rem 0.25rem 0.6rem rgba(0,0,0,0.05), 0 0.5rem 1.125rem rgba(75,0,0,0.05);
// }



// /*** STYLE ***/
// *,
// *:before,
// *:after {
//   box-sizing: border-box;
// }

// html,
// body {
//   margin: 0;
//   padding: 0;
// }

// body {
//   background-color: white;
//   color: $black;
//   font-family: 'Raleway', sans-serif;
// }

// main {
//   display: block;
//   margin: 0 auto;
//   max-width: 40rem;
//   padding: 1rem;
// }


// ol.gradient-list {
//   counter-reset: gradient-counter;
//   list-style: none;
//   margin: 1.75rem 0;
//   padding-left: 1rem;
//   > li {
//     background: $light;
//     border-radius: 0 0.5rem 0.5rem 0.5rem;
//     @extend %boxshadow;
//     counter-increment: gradient-counter;
//     margin-top: 1rem;
//     min-height: 3rem;
//     padding: 1rem 1rem 1rem 3rem;
//     position: relative;
//     &::before,
//     &::after {
//       background: linear-gradient(135deg, $blue 0%,$green 100%);
//       border-radius: 1rem 1rem 0 1rem;
//       content: '';
//       height: 3rem;
//       left: -1rem;
//       overflow: hidden;
//       position: absolute;
//       top: -1rem;
//       width: 3rem;
//     }
//     &::before {
//       align-items: flex-end;
//       @extend %boxshadow;
//       content: counter(gradient-counter);
//       color: $black;
//       display: flex;
//       font: 900 1.5em/1 'Montserrat';
//       justify-content: flex-end;
//       padding: 0.125em 0.25em;
//       z-index: 1;
//     }
//     @for $i from 1 through 5 {
//       &:nth-child(10n+#{$i}):before {
//         background: linear-gradient(135deg, rgba($green, $i * 0.2) 0%,rgba($yellow, $i * 0.2) 100%);
//       }
//     }
//     @for $i from 6 through 10 {
//       &:nth-child(10n+#{$i}):before {
//         background: linear-gradient(135deg, rgba($green, 1 - (($i - 5) * 0.2)) 0%,rgba($yellow, 1 - (($i - 5) * 0.2)) 100%);
//       }
//     }
//     + li {
//       margin-top: 2rem;
//     }
//   }
// }

.grid {
    // border-style: solid;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 1rem;
    > div {
      background: rgba(0, 0, 0, 0);
      padding: 1rem;
      position: relative;
      &::before {
        // for apsect ratio
        content: "";
        display: block;
        padding-bottom: 100%;
      }
      img {
        position: absolute;
        max-width: 100%;
  
        // Alternate Version
        // top: 0;
        // bottom: 0;
        // right: 0;
        // left: 0;
        // margin: auto;
  
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }