@import "../variables.scss";

.sponsors {
	padding: 20px 40px 40px ;
    // max-width: max-content;    
    margin-top: 50px;
    border-right: 3px solid $theme;
    border-left: 3px solid $theme;
    margin-bottom: 70px;

	&__image {
        // height: 2000;
        // border: 3px solid $theme;
		display: inline-block;
        vertical-align: top;
        width: 35%;
        margin: 0px 50px 30px 0%;
        // padding-left: 20px;
        // size: 50%;
        transition: transform .2s;
        
    }
    
    &__image:hover {
        transform: scale(1.1);
    }

  &__imageCompetition {
    // height: 2000;
    // border: 3px solid $theme;
    display: inline-block;
        vertical-align: top;
        // width: 30%;
        margin: 0px 50px 30px 0%;
        // padding-left: 20px;
        // size: 50%;
        transition: transform .2s;
        
    }
    
    &__imageCompetition:hover {
        transform: scale(1.1);
    }

	&__text {
        // border: 3px solid $theme;
		display: inline-block;
        width: 60%;
        // margin-right: auto;
        margin: 20px 0px 50px 0%;
        padding-right: 20px;
		@media (max-width: 620px) {
			width: 100%;
		}
  }
  
  &__textCompetition {
    // border: 3px solid $theme;
    display: inline-block;
    width: 70%;
    // margin-right: auto;
    // margin-top: 100px;
    margin: 60px 0px 50px 0%;
    padding-right: 20px;
    @media (max-width: 620px) {
      width: 100%;
    }
  }

  &__textSubmit {
    // border: 3px solid $theme;
    display: block;
    width: 10%;
    // margin-right: auto;
    // margin-top: 100px;
    margin: 0px 0px 50px 0%;
    padding-right: auto;
    padding-left: auto;
    margin-left: auto;
    margin-right: auto;
    @media (max-width: 620px) {
      width: 100%;
    }
  }
}


#slideshow { 
    overflow: hidden; 
    height: 510px; 
    width: 728px; 
    margin: 0 auto; 
  } 

  /* Style each of the sides  
  with a fixed width and height */ 
  .slide { 
    float: left; 
    height: 510px; 
    width: 728px; 
  } 

  /* Add animation to the slides */ 
  .slide-wrapper { 

    /* Calculate the total width on the 
    basis of number of slides */ 
    width: calc(728px * 4); 
      
    /* Specify the animation with the 
    duration and speed */ 
    animation: slide 15s ease-in-out infinite; 
    // animation-delay: 2s;
  } 

  /* Set the background color 
  of each of the slides */ 
  .slide:nth-child(1) { 
    background: black; 
  } 

  .slide:nth-child(2) { 
    background: black; 
  } 

  .slide:nth-child(3) { 
    background: black; 
  } 

  .slide:nth-child(4) { 
    background: black; 
  } 

  /* Define the animation  
  for the slideshow */ 
  @keyframes slide { 

    /* Calculate the margin-left for  
    each of the slides */ 
    20% { 
      margin-left: 0px; 
    } 

    40% { 
      margin-left: calc(-728px * 1); 
    } 

    60% { 
      margin-left: calc(-728px * 2); 
    } 

    80% { 
      margin-left: calc(-728px * 3); 
    } 
  }

.slideImg {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5px;
    margin-bottom: 5px;
    width: 98%;
    height: 98%;
}

.speakers {
  .card { 
    width: 100%;
    max-width: 300px;
    .card-banner {
      width: 100%;
      height: 0;
      padding-bottom: 100%;
      position: relative;
      overflow: hidden;
      & img {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        min-height: 100%;
        min-width: 100%;
        transform: translate(-50%, -50%);
        object-fit: cover;
      }
    } 
  }
}