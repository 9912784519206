#homepage {
  .hero {
    transition: 0.55s ease;
    user-select: none;
    height: calc(100 * var(--vh, 1vh));
    width: 100vw;
    overflow-x: hidden;
    position: relative;
    .hero-bg {
      height: 100%;
      background: $black-bright
        url("https://res.cloudinary.com/dmpjsarum/image/upload/e_grayscale/v1611354453/imvdfage0_qkgows.png");
      @include media("<=md") {
        background: $black-bright
        url("https://res.cloudinary.com/dmpjsarum/image/upload/v1610751383/image0_udom8e.png");
          // url("https://res.cloudinary.com/masonwang/image/upload/v1595467829/yam-website/hero/lg-color.jpg");
        background-size: cover;
      }
      background-size: cover;
    }

    .hero-content {
      height: 100%;
      .title {
        .line {
          height: 200px;
          overflow: hidden;
          @include media("<=md") {
            height: 160px;
          }
          @include media("<=sm") {
            height: 130px;
          }
        }
        h1 {
          font-family: "Raleway", -apple-system, BlinkMacSystemFont, "Segoe UI",
            "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
            "Helvetica Neue", sans-serif;
          color: $light-bright;
          font-size: 12em;
          text-shadow: 8px 8px 0px rgba(0, 0, 0, 0.55);
          @include media("<=md") {
            font-size: 9em;
          }
          @include media("<=sm") {
            font-size: 7em;
            text-shadow: 6px 6px 0px rgba(0, 0, 0, 0.55);
          }
          @include media("<=450px") {
            font-size: 6.5em;
            text-shadow: 6px 6px 0px rgba(0, 0, 0, 0.55);
          }
          font-weight: bold;
          .letter {
            display: inline-block;
          }
          .theme-peach {
            color: $theme-peach;
          }
          .theme-green {
            color: $theme-green;
          }
          .theme-blue {
            color: $theme-blue;
          }
        }
      }

      .slogan {
        .single {
          display: block;
        }
        .double,
        .triple {
          display: none;
        }

        @include media("<=md") {
          .double {
            display: block;
          }
          .triple,
          .single {
            display: none;
          }
        }

        @include media("<=sm") {
          .triple {
            display: block;
          }
          .double,
          .single {
            display: none;
          }
        }

        .line-slogan {
          height: 66px;
          position: relative;
          overflow: hidden;
          @include media("<=md") {
            height: 63px;
          }
          @include media("<=sm") {
            height: 59px;
          }
          @include media("<=xs") {
            height: 60px;
          }
          @include media("<=385px") {
            height: 55px;
          }

          h2 {
            color: $light-bright;
            position: absolute;
            font-size: 3.5em;
            font-weight: bold;
            position: relative;
            text-shadow: 5px 5px 0px rgba(0, 0, 0, 0.69);
            @include media("<=lg") {
              font-size: 3em;
              text-shadow: 4px 4px 0px rgba(0, 0, 0, 0.69);
            }

            @include media("<=385px") {
              font-size: 2.5em;
              text-shadow: 3px 3px 0px rgba(0, 0, 0, 0.69);
            }
          }
        }
      }

      .action-btn {
        background-color: $gray-dark;
        color: $light-bright;
        filter: drop-shadow(6px 6px 0 rgba(0, 0, 0, 0.69));
        &:hover {
          background-color: $gray;
          filter: drop-shadow(7px 9px 0 rgba(0, 0, 0, 0.6));
        }
        &.main-action {
          background-color: $theme;
          &:hover {
            background-color: $theme-bright;
          }
        }
      }
    }
    @media screen and (max-height: 540px) and (orientation: landscape) {
      // screw landscape phones but here i am being nice
      .hero-bg {
        background: $black-bright url("../../assets/img/hero/lg-color.jpg");
        background-size: cover;
      }

      .hero-content {
        .title {
          .line {
            height: 110px;
          }
          h1 {
            font-size: 6.2em;
          }
        }
        .slogan {
          .single {
            display: block;
          }
          .double,
          .triple {
            display: none;
          }
          .line-slogan {
            height: 40px;
            h2 {
              font-size: 2em;
            }
          }
        }

        .action-btn {
          font-size: 1.1em;
          font-weight: 500;
        }
      }
    }
  }

  // general about section
  .about-section {
    transition: 0.55s ease;
    padding: 0 10px;
    max-width: 100vw;
    overflow-x: hidden;
    .row {
      overflow-x: hidden;
      position: relative;
      padding-top: 2em;
      padding-bottom: 2em;
      h1 {
        font-family: "Ubuntu", -apple-system, BlinkMacSystemFont, "Segoe UI",
          "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
          "Helvetica Neue", sans-serif;
        font-weight: 700;
        font-size: 3.9em;
        @include media("<=lg") {
          font-size: 3.4em;
        }
        @include media("<=md") {
          font-size: 3em;
        }
        @include media("<=sm") {
          font-size: 2.69em;
        }
        .theme {
          color: $theme-darker;
        }
        .theme-peach {
          color: #e88e67;
        }
        .theme-green {
          color: #9fc379;
        }
        .theme-blue {
          color: #8191d8;
        }
      }

      h2 {
        font-family: "Courgette", -apple-system, BlinkMacSystemFont, "Segoe UI",
          "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
          "Helvetica Neue", sans-serif;
        font-size: 1.8em;
        @include media("<=lg") {
          font-size: 1.5em;
        }
        @include media("<=md") {
          font-size: 1.6em;
        }
        @include media("<=sm") {
          font-size: 1.5em;
        }
      }

      p {
        font-size: 1.3em;
        @include media("<=md") {
          font-size: 1.2em;
        }
        @include media("<=sm") {
          font-size: 1.15em;
        }
        b {
          color: $theme-darker;
        }
      }

      .email {
        * {
          color: $theme-darker;
          font-weight: bold;
        }
      }

      @media screen and (max-height: 540px) and (orientation: landscape) {
        min-height: calc(100 * var(--vh, 1vh));
        min-height: calc(100 * var(--vh, 1vh));
        h1 {
          font-size: 3em;
        }
        h2 {
          font-size: 2em;
        }
        p {
          font-size: 1.3em;
        }
      }
    }
  }

  // color customizations
  .about {
    transition: 0.55s ease;
    .row {
      transition: 0.55s ease;
      .action-btn {
        background-color: $gray-dark;
        color: $light-bright;
        filter: drop-shadow(6px 6px 0 rgba(0, 0, 0, 0.69));
        border: 0;
        &:hover {
          border: 0;
          filter: drop-shadow(7px 9px 0 rgba(0, 0, 0, 0.6));
        }
        &.main-action {
          background-color: $theme;
          &:hover {
            background-color: $theme-bright;
          }
        }
      }
    }
  }

  .blm-about {
    transition: 0.55s ease;
    background-color: black;
    color: $light-bright;
    .row {
      transition: 0.55s ease;
      p {
        b {
          color: white;
        }
      }

      .action-btn {
        background-color: black;
        border: 0.15em $light-bright solid;
        color: $light-bright;
        filter: drop-shadow(6px 6px 0 rgba(255, 255, 255, 0.69));
        &:hover {
          filter: drop-shadow(7px 9px 0 rgba(255, 255, 255, 0.6));
        }
        &.main-action {
          background-color: $light-bright;
          color: black;
        }
      }
    }
  }

  .comp-advert {
    transition: 0.55s ease;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .outreach-section {
    // padding: 1em;
    padding: 1em 1em;

    // @include media(">xs") {
    //   padding: 1em 2em;
    // }

    // @include media(">sm") {
    //   padding: 1em 3em;
    // }

    .outreach-banners {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1em;

      img {
        max-width: 100%;
        max-height: 100%;
      }

      @include media(">md") {
        flex-direction: row;
        justify-content: space-between;

        > div {
          width: 100%;
          height: 0;
          padding-bottom: 50%;
          position: relative;
        }
        img {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
        }
      }

    }

    .incentive-list-btn {
      display: block;
      margin-left: 0px;
      margin-right: auto;
      background-color: inherit;
      border: 0;
      font-size: 1em;
      position: relative;
      margin-top: 1em;
      
      &:not(.expand)::before {
        display: inline-block;
        margin-right: .255em;
        vertical-align: .255em;
        content: "";
        border-top: .3em solid;
        border-right: .3em solid transparent;
        border-bottom: 0;
        border-left: .3em solid transparent;
      }

      &.expand {
        &::before {
          display: inline-block;
          margin-right: .255em;
          vertical-align: .255em;
          content: "";
          border-bottom: .3em solid;
          border-right: .3em solid transparent;
          border-top: 0;
          border-left: .3em solid transparent;
        }
      }
      
      &:hover, &:focus {
        background-color: inherit;
        &::after {
          visibility: visible;
          transform: scaleX(1);
        }
      }

      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 2.5px;
        top: 88%;
        bottom: 0;
        left: 0;
        background-color: $gray-dark;
        transition: all 0.3s ease-in-out 0s;
        border-radius: 1.5px;
        visibility: hidden;
        transform: scaleX(0);
        transform-origin: left center;
      }
    }

    .incentive-list {
      max-height: 0px;
      height: auto;
      overflow: hidden;
      transition: all .5s ease;

      &.expand {
        max-height: 1000px;
      }
    }

    ul.incentive-list {
      list-style: none;
      margin: 1rem 0;
      padding-left: 1rem;

      >li {
        text-align: left;
        border-radius: 0.5rem;
        margin-top: 1rem;
        min-height: 1rem;
        padding: 0rem 1rem 0rem 1rem;
        position: relative;

        &::before,
        &::after {
          background: linear-gradient(135deg, $theme-blue 0%, $green 100%);
          border-radius: 1rem;
          content: '';
          height: 1rem;
          left: -.5rem;
          overflow: hidden;
          position: absolute;
          top: 0.25rem;
          width: 1rem;
        }

        &::before {
          align-items: flex-end;
          // content: counter(gradient-counter);
          // color: $black;
          display: flex;
          // font: 900 1.5em/1 'Montserrat';
          justify-content: flex-end;
          padding: 0.125em 0.25em;
          z-index: 1;
        }

        @for $i from 1 through 5 {
          &:nth-child(10n+#{$i}):before {
            background: linear-gradient(135deg, rgba($green, $i * 0.2) 0%, rgba($theme-peach, $i * 0.2) 100%);
          }
        }

        @for $i from 6 through 10 {
          &:nth-child(10n+#{$i}):before {
            background: linear-gradient(135deg, rgba($green, 1 - (($i - 5) * 0.2)) 0%, rgba($theme-peach, 1 - (($i - 5) * 0.2)) 100%);
          }
        }
      }
    }
  }
}
